import { useState, useContext, useEffect } from "react";
import "./login.css";
import axios from "axios";
import { Context } from "../store";
import { useNavigate } from "react-router-dom";
import { axios_ } from "../axios";

export default function Login({ onLogin, isLoginIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe] = useState(false);
  const [error, setError] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMesage] = useState(false);
  const { showMessage } = useContext(Context);
  const navigation = useNavigate();

  //new existing user
  // const [isSubmitted, setIsSubmitted] = useState(false);


  // const checkSubmissionStatus = async()=>{
  //   try{
  //     const response = await axios_.get(`check_submission_status/${email}`);
  //     setIsSubmitted(response.data.submitted);
  //   }catch(error){
  //     console.error('Error checking submission status:', error);
  //   }
  // };


  // useEffect(()=>{
  //   if(email){
  //     checkSubmissionStatus();
  //   }
  // }, [email]);


  async function handleReset(email) {
    try {
      if (email.trim() === "") {
        setShowModel(true);
        showMessage("Please enter user name", false);
        return;
      }
      setLoading(true);
      const response = await axios_.post("reset_pass", { email });
      if (response.succ) setShowModel(true);
      if (response.data.data) {
        navigation(`/reset_pass`);
      } else {
        showMessage(response.data.message);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  function handlePassword(e) {
    e.preventDefault();
    setPassword(e.target.value);
  }
  const handleLoginClick = (e) => {
    e.preventDefault();

    // if(isSubmitted){
    //   setError('“Your application has been submitted, If changes are needed, please contact the ellis foundation at 620-223-2232. Or you can email tami@theellisfoundation.org.”');
    //   return;
    // }

    if (rememberMe) {
      localStorage.setItem("remember", true);
    } else {
      localStorage.setItem("remember", false);
    }
    onLogin(email, password, setError);
  };

  function handleModel(e) {
    e.preventDefault();
    setShowModel(false);
    // window.location.href = "/";
  }

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="text-column">
          <h2>This content is restricted, please log in.</h2>

          <p>
            New users: use the username and password provided to you.
            <br />
            <br />
            If you’ve already registered, enter the username and password you created.
          </p>
        </div>

        <div className="form-column">
          <p></p>
          <p style={{ textAlign: "center", color: "red" }}>{message}</p>
          <div className="login-form">
            <h2 className="head">Existing Users Login</h2>
            <p className="head" style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {error}
            </p>
            <form>
              <input className="form-input" type="email" placeholder="User Name" onChange={handleEmail} value={email} />
              <input className="form-input" type="password" placeholder="Password" onChange={handlePassword} value={password} />

              <div className="remember-forgot">
                <label className="remember-me">
                  <input type="checkbox" name="remember" />
                  Remember me
                </label>
              </div>

              <div className="login-button">
                <button className="submit-button" onClick={handleLoginClick} disabled={isLoginIn ? true : false}>
                  {isLoginIn ? "Loggin In" : "LOGIN"}
                </button>
                <p className="register-link">
                  Forgot password?{" "}
                  <a href="#!" className="link-danger" onClick={() => handleReset(email)}>
                    Click here to reset
                  </a>
                  {loading && (
                    <div style={{ display: "flex" }}>
                      <span className="loader"></span>
                    </div>
                  )}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* {showModel && (
        <div className="popup">
          <div className="popup-content">
            <p>{message ? message : "Email sent please check"} </p>
            <button onClick={handleModel}>ok</button>
          </div>
        </div>
      )} */}
    </div>
  );
}
