import React, { useState } from "react";
import "./form.css";
import { useEffect } from "react";

const Family = ({ onNext, onPrev, updateFormData, formDataMain, showModel, setShowModel }) => {
    const [selectedValue, setSelectedValue] = useState("Select One");
    const [textAreaContent, setTextAreaContent] = useState("");

    const options = [
        "Select One",
        "Under $15,000",
        "$15,000 to $20,000 ",
        "$20,000 to $25,000",
        "$25,000 to $30,000",
        "$35,000 to $50,000",
        "$50,000 to $75,000",
        "$75,000 to $100,000",
        "$100,000 to $150,000",
        "$150,000 to $200,000",
        "over $200,000",
    ];

    useEffect(() => {
        setSelectedValue(formDataMain.familyIncome);
        setTextAreaContent(formDataMain.familyConsiderations);
    }, []);

    const handleDropdownChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        updateFormData({
            familyIncome: newValue,
            familyConsiderations: textAreaContent,
        });
    };

    const handleTextAreaChange = (event) => {
        const newText = event.target.value;
        setTextAreaContent(newText);
        updateFormData({
            familyIncome: selectedValue,
            familyConsiderations: newText,
        });
    };

    return (
        <div className="family1">
            <p className="divider">
                <hr className="hr " />
            </p>

            <div className="fami2">
                <p>In the space provided, please indicate your family's adjusted gross income from last year's income tax return.</p>
                <h3>Adjusted Gross Income</h3>

                <select style={{ width: "23rem", height: "2.7rem", fontSize: "medium" }} id="dropdown" value={selectedValue} onChange={handleDropdownChange}>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <h3>Other Financial Consideration Which Needs to be Noted</h3>
                <textarea id="textArea" value={textAreaContent} onChange={handleTextAreaChange} className="fam" />
            </div>
            <p className="divider">
                <hr className="hr " />
            </p>
            <button className="button-form" onClick={onPrev} >
                PREVIOUS
            </button>
            <button className="button-form2" onClick={(event) => onNext(event)} style={{marginLeft:'25.5rem'}}>
                NEXT
            </button>
        </div>
    );
};

export default Family;
