
import { useContext, useEffect, useState } from "react";
import Agreement from "./Agreement";
import ReleaseAuth from "./ReleaseAuth";
import SeniorPhoto from "./SeniorPhoto";
import Personal from "./Personal";
import School from "./School";
import Family from "./Family";
import Financial from "./Financial";
import "./form.css";
import { axios_ } from "../axios";
import { Context } from "../store";

//states
const dbMapping = {
  Agreement: "agreement",
  ReleaseAuthFileMetadata: "releaseauth",
  ReferredBy: "seniorPhotoData.referredBy",
  SpecificScholarship: "seniorPhotoData.specificScholarship",
  TodayDate: "seniorPhotoData.todayDate",
  FullName: "seniorPhotoData.fullName",
  NameUsed: "seniorPhotoData.nameUsed",
  AddressLine: "seniorPhotoData.address",
  AddressLine2: "seniorPhotoData.addressLine2",
  City: "seniorPhotoData.city",
  StateLine: "seniorPhotoData.state",
  Zip: "seniorPhotoData.zip",
  Country: "seniorPhotoData.Country",
  CellPhone: "seniorPhotoData.cellPhone",
  HomePhone: "seniorPhotoData.homePhone",
  Email: "seniorPhotoData.email",
  Dob: "seniorPhotoData.dob",
  Ssn: "seniorPhotoData.ssn",
  Sex: "seniorPhotoData.sex",
  Ethnicity: "seniorPhotoData.ethnicity",
  ActOrSatScore: "personalData.actOrSatScore",
  HighSchoolGPA: "personalData.highSchoolGPA",
  HighSchoolName: "personalData.highSchoolName",
  SelectSchool: "personalData.selectSchool",
  OtherHighSchoolName: "personalData.otherHighSchoolName",
  HighSchoolCounselor: "personalData.highSchoolCounselor",
  HighSchoolAddress: "personalData.highSchoolAddress",
  HighSchoolCity: "personalData.city",
  HighSchoolState: "personalData.state",
  HighSchoolZip: "personalData.zip",
  HighSchoolCountry: "personalData.Country",
  HighSchoolPhone: "personalData.highSchoolPhone",
  HighSchoolEmail: "personalData.highSchoolEmail",
  College1Name: "collegeForms[0].selectCollege",
  College1CareerObjective: "collegeForms[0].careerobj",
  College1Degree: "collegeForms[0].degree",
  College1Address: "collegeForms[0].address",
  College1AddressLine2: "collegeForms[0].addressLine2",
  College1City: "collegeForms[0].city",
  College1Zip: "collegeForms[0].zip",
  College1Phone: "collegeForms[0].collegePhone",
  College1State: "collegeForms[0].state",
  College1Country: "collegeForms[0].Country",
  College1Email: "collegeForms[0].collegeEmail",
  OtherCollege1CareerObj: "collegeForms[0].otherCollegeCareer",
  College2Name: "collegeForms[1].selectCollege",
  College2CareerObjective: "collegeForms[1].careerobj",
  College2Degree: "collegeForms[1].degree",
  College2Address: "collegeForms[1].address",
  College2AddressLine2: "collegeForms[1].addressLine2",
  College2City: "collegeForms[1].city",
  College2Zip: "collegeForms[1].zip",
  College2Phone: "collegeForms[1].collegePhone",
  College2State: "collegeForms[1].state",
  College2Country: "collegeForms[1].Country",
  College2Email: "collegeForms[1].collegeEmail",
  OtherCollege2CareerObj: "collegeForms[1].otherCollegeCareer",
  College3Name: "collegeForms[2].selectCollege",
  College3CareerObjective: "collegeForms[2].careerobj",
  College3Degree: "collegeForms[2].degree",
  College3Address: "collegeForms[2].address",
  College3AddressLine2: "collegeForms[2].addressLine2",
  College3City: "collegeForms[2].city",
  College3Zip: "collegeForms[2].zip",
  College3Phone: "collegeForms[2].collegePhone",
  College3State: "collegeForms[2].state",
  College3Country: "collegeForms[2].Country",
  College3Email: "collegeForms[2].collegeEmail",
  OtherCollege3CareerObj: "collegeForms[2].otherCollegeCareer",
  Fam: "fam",
  FatherName: "fathername",
  FatherEmployer: "fatheremp",
  FatherPhone: "fatherphone",
  FatherEmail: "fatheremail",
  MotherName: "mothername",
  MotherEmployer: "motheremp",
  MotherPhone: "motherphone",
  MotherEmail: "motheremail",
  LegalGuardianName: "legal",
  NumberOfFamilyMembers: "nofam",
  NumberOfChildren: "nochild",
  ChildrenAges: "childage",
  NumberOfChildrenInCollege: "childcol",
  SingleParentFamily: "singleparentfamily",
  FamilyMemberReceivedScholarship: "anotherfam",
  FirstGenerationCollegeStudent: "gen",
  FamilyIncome: "familyIncome",
  FamilyConsiderations: "familyConsiderations",
  IsChecked: "isChecked",
  SelectedValue: "selectedValue",
  HonorsClassesContent: "honorsClassesContent",
  ExtracurricularContent: "extracurricularContent",
  AwardsContent: "awardsContent",
  CommunityContent: "communityContent",
  UserID: "UserID",
  CurrentStep: "currentStep",
  EssayContent: "essayContent",
};

const steps = [
  { component: Agreement, name: "Agreement" },
  { component: ReleaseAuth, name: "Senior Photo" },
  { component: SeniorPhoto, name: "Personal Information" },
  { component: Personal, name: "School" },
  { component: School, name: "Family" },
  { component: Family, name: "Financial" },
  { component: Financial, name: "Additional Information" },
];
// after onSubmit
const MultiStepForm = () => {
  const { setSelectedFile, formData, setFormData, onSubmit,currentStep, setCurrentStep, loading, } = useContext(Context);

  const [isChecked, setIsChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [error] = useState({});
  const [isVallid, setIsValid] = useState(false);
  const [showModel, setShowModel] = useState(false);

  const handleStepClick = (index) => {
    
    if (index === currentStep) return;

    setCurrentStep(index);
  };


  const [formData1, setFormData1] = useState({
    referredBy: "",
    specificScholarship: "",
    todayDate: "",
    fullName: "",
    nameUsed: "",
    address: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    County: "",
    cellPhone: "",
    homePhone: "",
    email: "",
    dob: "",
    ssn: "",
    sex: "",
    ethnicity: "",
  });

  let token = localStorage.getItem("token");

  async function getData() {
    try {
      const response = await axios_.post("/getUserData", { token });
      const userData = response?.data?.data;
      const objData = {};
      // HighSchoolEmail: "personalData.highSchoolEmail",
      // College1Name: "collegeForms[0].selectCollege",
      Object.entries(dbMapping).forEach(([key, value]) => {
        if (value.includes(".")) {
          if (value.includes("[")) {
            let index = value.split("[")[1].substring(0, 1); /// get index
            let objectName = value.split("[")[0]; // get arr name
            if (!objData[objectName]) objData[objectName] = [];
            if (!objData[objectName][index]) objData[objectName][index] = {};
            objData[objectName][index][value.split(".")[1]] = userData[key];
          } else {
            if (!objData[value.split(".")[0]]) objData[value.split(".")[0]] = {};
            objData[value.split(".")[0]][value.split(".")[1]] = userData[key];
          }
        } else {
          objData[value] = userData[key];
        }
      });
      // Remove Blank Colleges
      objData.collegeForms = objData.collegeForms.filter((coll) => coll.selectCollege != null);

      setFormData(objData);
      setFormData1(objData.seniorPhotoData);
      setIsChecked(objData.agreement);

      setCurrentStep(parseInt(objData?.currentStep) || 0);
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const validateForm = (requiredFields, formDataToValidate) => {
    const isValid = requiredFields.every((field) => !!formDataToValidate[field]);
    setIsFormValid(isValid);
  };

  const onNext = (
    e,
    error = "",
    globalError = () => {},
    validateMail = () => {
      return true;
    },
    formValidation = () => {}
  ) => {
    let hasError = globalError();
    formValidation();

    let isMail = validateMail();
    if (!isMail) {
      return;
    }
    if (hasError) {
      return;
    }

    if (isFormValid) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    if (!isFormValid) {
    }
    if (isFormValid) {
      e.preventDefault();
      setCurrentStep((prevStep) => prevStep + 1);
      window.scrollTo(0, 0);
    } else {
    }
  };

  const onPrev = (e) => {
    e.preventDefault();
    setCurrentStep((prevStep) => prevStep - 1);
    window.scrollTo(0, 0);
  };

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const isLastStep = currentStep === steps.length - 1;

  const CurrentStepComponent = steps[currentStep].component;

  

  return (
    <div>
      <div>
        <div>
          <div className="step">
            <ul className="step-indicator">
              {steps.map((step, index) => (
                <li key={index} className={index === currentStep ? "active" : "" }   onClick={() => handleStepClick(index)}>
                  {index + 1 + " "}
                  {step.name}
                  
                </li>
              ))}
            </ul>
          </div>
        </div>
        <CurrentStepComponent
          validateForm={validateForm}
          error={error}
          onNext={isLastStep ? onSubmit : onNext}
          onPrev={onPrev}
          onSubmitForm={onSubmit}
          updateFormData={updateFormData} // Pass this function to update form data
          formDataMain={formData} // Optionally pass current form data to step component
          isLastStep={isLastStep}
          isValid={isVallid}
          loading={loading}
          setSelectedFile={setSelectedFile}
          showModel={showModel}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          formData1={formData1}
          setFormData1={setFormData1}
          setShowModel={setShowModel}

          // onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default MultiStepForm;
