import { createContext, useState } from "react";
import { axios_ } from "./axios";
export const Context = createContext({
    showMessage: () => {},
    message: undefined,
    success: undefined,
    selectedFile: undefined,
    setSelectedFile: () => {},
    onSubmit: () => {},
    formData: {},
    setFormData: () => {},
    currentStep: 0,
    setCurrentStep: () => {},
    loading: false,
    setLoading: () => {},
    isAuthenticated: false,
    setAuthenticated: () => {},
    handleSubmit:()=>{},
});

export default function ContextProvider({ children }) {
    const [message, setMessage] = useState(undefined);
    const [success, setSuccess] = useState(undefined);
    const [formData, setFormData] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setAuthenticated] = useState(false);
    function showMessage(message, success) {
        setSuccess(success);
        setMessage(message);
        setTimeout(() => {
            setSuccess(undefined);
            setMessage(undefined);
        }, 5000);
    }

    async function onSubmit() {
        const fileData = new FormData();
        let fileName;

        setLoading(true);

        try {
            if (selectedFile) {
                fileData.append("file", selectedFile);
                let res = await axios_.post("upload", fileData);
                if (res.status === 200) {
                    // localStorage.removeItem("rememberme","");
                    fileName = res.data.message;
                    
                }
            }
        } catch (e) {
            console.log("Error in uploading file", e);
        }

        try {
            if (fileName) formData.releaseauth = fileName;
            formData.currentStep = currentStep;
            const response = await axios_.post("/", formData);
          
            console.log("response.status", response.status);
            if (response.status === 200) {
                showMessage("Data is saved successfully", true);
                // setLoading(false);
            } else {
                showMessage("Failed to save, Please try again", false);
            }
        } catch (error) {
            setLoading(false);
            showMessage("Error in saving data, Please try again", false);
        } finally{
            setLoading(false);
        }
    }

    let values = {
        showMessage,
        message,
        success,
        selectedFile,
        onSubmit,
        setSelectedFile,
        formData,
        setFormData,
        currentStep,
        setCurrentStep,
        loading,
        setLoading,
        isAuthenticated,
        setAuthenticated,
    };
    return <Context.Provider value={values}>{children}</Context.Provider>;
}
