import React, { useContext } from "react";
import { useState } from "react";
import "./rel.css";

import { Context } from "../store";

const ReleaseAuth = ({ onNext, onPrev, updateFormData, showModel, setShowModel }) => {
  const [isValidFile, setIsValidFile] = useState(true);
  const { setSelectedFile } = useContext(Context);
  const validFileType = ["jpg", "png", "jpeg"];
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setIsValidFile(true);
    if (file) {
      let name = file.name.split(".");
      if (validFileType.includes(name[name.length - 1])) {
        setSelectedFile(file); // Now correctly updates the selectedFile state
        updateFormData({
          releaseauth: file.name,
        });
      } else {
        setIsValidFile(false);
        setSelectedFile(null);
        updateFormData({
          releaseauth: null,
        });
      }
    }
  };

  return (
    <div style={{ fontSize: "small" }}>
      <div className="star">
        <h2>Senior Photo</h2>
        <input style={{ fontSize: "small", marginLeft: "-0.7rem" }} type="file" onChange={handleFileChange} />
        <div
          style={{
            display: "flex",
            width: "55rem",
            color: "brown",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        >
          {!isValidFile && <p>The uploaded file type is not allowed. Must be one of the following: jpg, png, jpeg</p>}
        </div>
      </div>
      <p className="rel-p">Accepted file types: jpg, png, jpeg, Max. file size: 32 MB.</p>{" "}
      <p className="rel-p">
        Please upload a senior picture, this does not need to be a professional picture but it must be only of you. If you don't have a picture, you may email <br /> one to us later.
      </p>
      <div style={{ marginBottom: "9rem" }}>
        <p className="divider">
          <hr className="hr " />
        </p>
        <button className="button-form" onClick={onPrev}>
          PREVIOUS
        </button>
        <button className="button-form2" onClick={(event) => onNext(event)} style={{marginLeft:"27rem"}}>
          NEXT
        </button>
      </div>
    </div>
  );
};

export default ReleaseAuth;
